// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OFO2_9kcjekma8IROzkM {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.P9POiAIseQYVz8aWHGfq {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0.75rem 0.5rem;
  border-radius: 7px;
  width: 550px;
  height: 600px;
  background-color: white;
}

.BXpnDFTM_t3ncj8oNpTZ {
  border: 1px solid #da3b3b !important;
}

.rMBnwxIKDPSzpgZtiCHQ {
  text-align: center;
  padding: 0.2rem;
  width: 80%;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #b1b1b1;
}

@media (max-width: 570px) {
  .P9POiAIseQYVz8aWHGfq {
    width: 380px;
    height: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Form/Inputs/SearchImage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,0CAA0C;EAC1C,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".main {\n  position: fixed;\n  z-index: 10000;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: rgba(128, 128, 128, 0.2);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.menu {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 0.75rem 0.5rem;\n  border-radius: 7px;\n  width: 550px;\n  height: 600px;\n  background-color: white;\n}\n\n.problem {\n  border: 1px solid #da3b3b !important;\n}\n\n.input {\n  text-align: center;\n  padding: 0.2rem;\n  width: 80%;\n  color: black;\n  font-size: 1rem;\n  border-radius: 5px;\n  border: 1px solid #b1b1b1;\n}\n\n@media (max-width: 570px) {\n  .menu {\n    width: 380px;\n    height: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var main = `OFO2_9kcjekma8IROzkM`;
export var menu = `P9POiAIseQYVz8aWHGfq`;
export var problem = `BXpnDFTM_t3ncj8oNpTZ`;
export var input = `rMBnwxIKDPSzpgZtiCHQ`;
export default ___CSS_LOADER_EXPORT___;
