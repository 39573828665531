// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b_0BOiPVCWpeN6SCCYl8 {
  gap: 28px;
}

.aaDQUJ9FJHr4nS4SdcOf {
  z-index: 5;
}

.VzNBuerUucaHi9trjZUz {
  min-width: 70px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: #ff9800;
  border-radius: 5px;
}

@media (max-width: 450px) {
  .b_0BOiPVCWpeN6SCCYl8 {
    gap: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Game/GameFooter.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".footerSteps {\n  gap: 28px;\n}\n\n.footer {\n  z-index: 5;\n}\n\n.taskCounter {\n  min-width: 70px;\n  font-size: 18px;\n  font-weight: 600;\n  color: white;\n  background-color: #ff9800;\n  border-radius: 5px;\n}\n\n@media (max-width: 450px) {\n  .footerSteps {\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var footerSteps = `b_0BOiPVCWpeN6SCCYl8`;
export var footer = `aaDQUJ9FJHr4nS4SdcOf`;
export var taskCounter = `VzNBuerUucaHi9trjZUz`;
export default ___CSS_LOADER_EXPORT___;
