"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.games = exports.NO_ANSWER = void 0;
const game_1 = require("newGames/types/game");
const Quiz_1 = require("newGames/forms/Quiz/Quiz");
const Table_1 = require("newGames/forms/Table/Table");
const nanoid_1 = require("nanoid");
const ExtendedAnswer_1 = require("./ExtendedAnswer/ExtendedAnswer");
const Table_2 = require("./Table/Table");
const Quiz_2 = require("./Quiz/Quiz");
const game_2 = require("newGames/types/game");
const ExtendedAnswerBackground_1 = require("./ExtendedAnswer/ExtendedAnswerBackground");
const QuizBackground_1 = require("./Quiz/QuizBackground");
const TableBackground_1 = require("./Table/TableBackground");
const QuizPdf_1 = require("./Quiz/QuizPdf");
const ExtendedAnswerPdf_1 = require("./ExtendedAnswer/ExtendedAnswerPdf");
const FindCouple_1 = require("newGames/forms/FindCouple/FindCouple");
const TablePdf_1 = require("./Table/TablePdf");
const FindCoupleContext_1 = require("./FindCouple/FindCoupleContext");
const FindCoupleBackground_1 = require("./FindCouple/FindCoupleBackground");
const onlyQuestionFom = () => null;
exports.NO_ANSWER = "no_answer";
exports.games = {
    [game_1.GAMES.extendedAnswer]: {
        label: "Расширенный ответ",
        game: ExtendedAnswer_1.ExtendedAnswer,
        pdf: ExtendedAnswerPdf_1.ExtendedAnswerPdf,
        form: onlyQuestionFom,
        results: (tasks, userAnswers) => {
            const result = {
                all: "--",
                user: "--",
                table: [["Вопрос", "Ответ пользователя"]],
            };
            for (let key in tasks) {
                const task = tasks[key];
                const answer = userAnswers[key] || {};
                result.table.push([
                    { question: task.question, image: task.index },
                    (answer["main"] == exports.NO_ANSWER ? "Не знаю" : answer["main"]) ||
                        "Отсутствует",
                ]);
            }
            return result;
        },
        noAnswer: () => {
            return { id: "main", value: exports.NO_ANSWER };
        },
        background: {
            component: ExtendedAnswerBackground_1.ExtendedAnswerBackground,
            check: (task) => !!(task === null || task === void 0 ? void 0 : task.question),
        },
        addPositions: () => 1,
    },
    [game_1.GAMES.quiz]: {
        label: "Викторина",
        game: Quiz_2.Quiz,
        form: Quiz_1.QuizForm,
        background: {
            component: QuizBackground_1.QuizBackground,
            check: (task) => { var _a; return !!(task === null || task === void 0 ? void 0 : task.question) && !!((_a = task === null || task === void 0 ? void 0 : task.positions) === null || _a === void 0 ? void 0 : _a.questions); },
        },
        pdf: QuizPdf_1.QuizPdf,
        results: (tasks, userAnswers) => {
            const result = {
                all: 0,
                user: 0,
                table: [["Вопрос", "Ответ пользователя", "Правильный ответ", "Оценка"]],
            };
            for (let key in tasks) {
                const task = tasks[key];
                const { positions, question } = task;
                const { trueAnswers, questions } = positions;
                const userPick = userAnswers[key];
                let thisTaskAll = 0;
                let userTaskCount = 0;
                trueAnswers.forEach((an) => {
                    result.all += 1;
                    thisTaskAll += 1;
                    if (userPick[an]) {
                        result.user += 1;
                        userTaskCount += 1;
                    }
                });
                const userTableAnswer = [];
                const allTableAnswer = [];
                questions.forEach((element, index) => {
                    const { id, text } = element;
                    if (trueAnswers.includes(id)) {
                        allTableAnswer.push(text || `Изображение ${index + 1}`);
                    }
                    if (userPick[id]) {
                        userTableAnswer.push(text || `Изображение ${index + 1}`);
                    }
                });
                let mark = game_2.Marks.bad;
                if (thisTaskAll == userTaskCount) {
                    mark = game_2.Marks.good;
                }
                else if (userTaskCount !== 0) {
                    mark = game_2.Marks.medium;
                }
                result.table.push([
                    { question: task.question, image: task.index },
                    userTableAnswer.join(", "),
                    allTableAnswer.join(", "),
                    mark,
                ]);
            }
            return result;
        },
        noAnswer: () => {
            return { id: exports.NO_ANSWER, value: exports.NO_ANSWER };
        },
        addPositions: () => {
            const questions = new Array(2)
                .fill(0)
                .map(() => ({ id: (0, nanoid_1.nanoid)(), text: "" }));
            const trueAnswers = [questions[0].id];
            return { questions, trueAnswers };
        },
    },
    [game_1.GAMES.table]: {
        label: "Таблица",
        game: Table_2.Table,
        form: Table_1.TableForm,
        background: {
            component: TableBackground_1.TableBackground,
            check: (task) => { var _a, _b; return !!((_a = task === null || task === void 0 ? void 0 : task.positions) === null || _a === void 0 ? void 0 : _a.rows) && !!((_b = task === null || task === void 0 ? void 0 : task.positions) === null || _b === void 0 ? void 0 : _b.columns); },
        },
        pdf: TablePdf_1.TablePdf,
        results: (tasks, userAnswers) => {
            const result = {
                all: 0,
                user: 0,
                table: [["Вопрос", "Ответ пользователя", "Правильный ответ", "Оценка"]],
            };
            for (let key in tasks) {
                const task = tasks[key];
                const taskAnswer = userAnswers[key];
                const { positions } = task;
                const { rows, columns } = positions;
                rows.forEach((row) => {
                    let rowQuestion = "";
                    for (let input of row) {
                        if (input.type == "row") {
                            rowQuestion = input.text;
                        }
                        else if (input.type == "answer") {
                            result.all += 1;
                            result.user =
                                input.text == taskAnswer[input.id]
                                    ? result.user + 1
                                    : result.user;
                            const col = columns.find((element) => element.id == input.col);
                            result.table.push([
                                { question: `${col.text} + ${rowQuestion}` },
                                taskAnswer[input.id] == exports.NO_ANSWER
                                    ? "Не знаю"
                                    : taskAnswer[input.id] || "Отсутствует",
                                input.text,
                                taskAnswer[input.id] == input.text ? game_2.Marks.good : game_2.Marks.bad,
                            ]);
                        }
                    }
                });
            }
            return result;
        },
        noAnswer: (currentTask, userAnswer) => {
            const { positions } = currentTask;
            const { rows } = positions;
            for (let row of rows) {
                for (let input of row) {
                    if (input.type == "answer" &&
                        input.text !== userAnswer[input.id] &&
                        !(userAnswer[input.id] == exports.NO_ANSWER)) {
                        return { id: input.id, value: exports.NO_ANSWER };
                    }
                }
            }
        },
        addPositions: () => {
            const columns = new Array(1).fill({ id: (0, nanoid_1.nanoid)(), text: "" });
            const rows = [];
            columns.forEach((elem, index) => {
                const row = [];
                for (let i = 0; i < columns.length + 1; i++) {
                    let type = "answer";
                    let col = elem.id;
                    const standart = { id: (0, nanoid_1.nanoid)(), text: "" };
                    if (i == 0) {
                        type = "row";
                        col = "";
                    }
                    row.push(Object.assign(Object.assign({}, standart), { type, col }));
                }
                rows.push(row);
            });
            return { columns, rows: rows };
        },
    },
    [game_1.GAMES.findCouple]: {
        label: "Найди пару",
        form: FindCouple_1.FindCouple,
        game: FindCoupleContext_1.FindCoupleContext,
        addPositions: () => {
            const colors = [
                "#28B1FF",
                "#9747FF",
                "#22CD29",
                "#FFA100",
                "#FF47BF",
                "#FF282B",
            ];
            const questions = new Array(2)
                .fill(0)
                .map(() => ({ id: (0, nanoid_1.nanoid)(), text: "" }));
            const answers = questions.map((elem) => ({
                id: `${elem.id}_answer`,
                text: "",
                color: colors[Math.floor(Math.random() * colors.length)],
            }));
            return { questions, answers };
        },
        noAnswer: (currentTask, userAnswer) => {
            const { positions } = currentTask;
            const { answers, questions } = positions;
            console.log(answers);
            for (let key of questions) {
                if (!userAnswer[`${key.id}_answer`]) {
                    return { id: `${key.id}_answer`, value: true };
                }
            }
        },
        background: {
            component: FindCoupleBackground_1.FindCoupleBackground,
            check: (task) => { var _a, _b; return !!((_a = task === null || task === void 0 ? void 0 : task.positions) === null || _a === void 0 ? void 0 : _a.questions) && !!((_b = task === null || task === void 0 ? void 0 : task.positions) === null || _b === void 0 ? void 0 : _b.answers); },
        },
        results: (tasks, userAnswers) => {
            const result = {
                all: 0,
                user: 0,
                table: [["Вопрос", "Ответ пользователя", "Правильный ответ", "Оценка"]],
            };
            for (let key in tasks) {
                const task = tasks[key];
                const { positions } = task;
                const { questions, answers } = positions;
                questions.forEach((element, index) => {
                    var _a, _b;
                    let mark = false;
                    if (((_a = userAnswers[key]) === null || _a === void 0 ? void 0 : _a[element.id]) == `${element.id}_answer`) {
                        result.user += 1;
                        mark = true;
                    }
                    result.all += 1;
                    const userAnswer = answers.find((el) => { var _a; return el.id == ((_a = userAnswers[key]) === null || _a === void 0 ? void 0 : _a[element.id]); });
                    console.log((_b = userAnswers[key]) === null || _b === void 0 ? void 0 : _b[element.id], answers, userAnswer);
                    const realAnswer = answers.find((el) => el.id == `${element.id}_answer`);
                    result.table.push([
                        { text: element.text, taskIndex: key, image: element.id },
                        {
                            text: userAnswer === null || userAnswer === void 0 ? void 0 : userAnswer.text,
                            taskIndex: key,
                            image: userAnswer === null || userAnswer === void 0 ? void 0 : userAnswer.id,
                        },
                        {
                            text: realAnswer === null || realAnswer === void 0 ? void 0 : realAnswer.text,
                            taskIndex: key,
                            image: realAnswer === null || realAnswer === void 0 ? void 0 : realAnswer.id,
                        },
                        mark ? game_2.Marks.good : game_2.Marks.bad,
                    ]);
                });
            }
            return result;
        },
    },
};
