// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ak38iiF8N1IReJcaEodj {
  min-height: 200px;
  min-width: 280px;
  border-radius: 7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c57UzBa4q8EbWxRb7B_I {
  border: 4px solid var(--fiol);
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Form/FormTheme.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".theme {\n  min-height: 200px;\n  min-width: 280px;\n  border-radius: 7px;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.isTheme {\n  border: 4px solid var(--fiol);\n}\n"],"sourceRoot":""}]);
// Exports
export var theme = `ak38iiF8N1IReJcaEodj`;
export var isTheme = `c57UzBa4q8EbWxRb7B_I`;
export default ___CSS_LOADER_EXPORT___;
