"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUrl = void 0;
exports.formatMilliseconds = formatMilliseconds;
const constants_1 = require("newGames/constants");
function formatMilliseconds(ms) {
    const hours = Math.floor(ms / 3600000);
    ms -= hours * 3600000;
    const minutes = Math.floor(ms / 60000);
    ms -= minutes * 60000;
    const seconds = Math.floor(ms / 1000);
    const format = (num) => num.toString().padStart(2, "0");
    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
}
const createUrl = (url) => {
    if (!url) {
        return "";
    }
    if (!url.includes("http")) {
        return `${constants_1.BASE_URL}${url}`;
    }
    return url;
};
exports.createUrl = createUrl;
