
import * as styles from './style.module.css';
const colorArray = ['#EEB157', '#8E86E7', '#3B8F44', '#E88F9A', '#85E5A0', '#81CDF2', '#436BF8', '#EE57E8']

function CheckYourself({ game }) {
    return (
        <div className='exercisesCardBg_content'>
            <div className={styles.container}>
                {game.map((_, key) => {
                    if (key < 8) {
                        return <div className={styles.item} style={{color: colorArray[key]}}>
                            {key+1}
                        </div>
                    }
                })}
            </div>
        </div>
    );
}

export default CheckYourself;
