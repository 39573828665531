"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validators = exports.Validates = void 0;
var Validates;
(function (Validates) {
    Validates["NO_EMPTY"] = "noEmpty";
})(Validates || (exports.Validates = Validates = {}));
const noEmpty = (value) => {
    const result = !!value;
    if (!result) {
        return "Обязательное поле";
    }
    return "";
};
exports.validators = {
    [Validates.NO_EMPTY]: noEmpty,
};
