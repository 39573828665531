// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hN1KBuJxyqTxDRMSi6Pe {
  min-height: 60px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/forms/FindCouple/FinndCouple.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".question {\n  min-height: 60px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export var question = `hN1KBuJxyqTxDRMSi6Pe`;
export default ___CSS_LOADER_EXPORT___;
