// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FxXyVuvTADuCEPu1sPgf {
    gap: 15px;
    padding: 24px 15px;
  }`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Game/GamePlay.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;EACpB","sourcesContent":[".gamePlay {\n    gap: 15px;\n    padding: 24px 15px;\n  }"],"sourceRoot":""}]);
// Exports
export var gamePlay = `FxXyVuvTADuCEPu1sPgf`;
export default ___CSS_LOADER_EXPORT___;
