// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S9oERB4jmd6o13lnxbwv {
  transform: translate(0px, 70px);
  animation: taOIZpssIk9HBrvisZuu 70s linear infinite;
}

@keyframes taOIZpssIk9HBrvisZuu {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Form/Themes/animations/CloudAnimation.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,mDAAsC;AACxC;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".cloudAnimation {\n  transform: translate(0px, 70px);\n  animation: moveSvg 70s linear infinite;\n}\n\n@keyframes moveSvg {\n  0% {\n    transform: translateX(-100%);\n  }\n  100% {\n    transform: translateX(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var cloudAnimation = `S9oERB4jmd6o13lnxbwv`;
export var moveSvg = `taOIZpssIk9HBrvisZuu`;
export default ___CSS_LOADER_EXPORT___;
