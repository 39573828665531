"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameModal = void 0;
const core_1 = require("@rematch/core");
const createModalState = () => {
    return {
        modal: "start",
    };
};
exports.gameModal = (0, core_1.createModel)()({
    state: createModalState(),
    reducers: {
        addModal(state, modal) {
            return Object.assign(Object.assign({}, state), { modal });
        },
        removeModal(state) {
            return Object.assign(Object.assign({}, state), { modal: "" });
        },
    },
    effects: (dispatch) => ({}),
});
