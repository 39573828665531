// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctRMMjF0sw989JFZ0L3e {
  font-family: "Rubik";
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
}

.HZoeGjVuga_96UNL_J0E {
  color: var(--darkBlue);
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

.mPCTKYOT65ubXFkMQpHH {
  text-align: center;
  border: 1px solid var(--darkBlue);
  color: var(--darkBlue);
  border-radius: 5px;
  padding: 0.25rem;
}

.BwfFHV6_J_BWCfJXVQXN {
  text-align: center;
  font-weight: 400;
  background-color: #7c3aed;
  font-size: 1rem;
  color: white;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/games/FromLink.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".fromLink {\n  font-family: \"Rubik\";\n  font-size: 1.1rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.hello {\n  color: var(--darkBlue);\n  text-align: center;\n  font-weight: bold;\n  font-size: 1.3rem;\n}\n\n.input {\n  text-align: center;\n  border: 1px solid var(--darkBlue);\n  color: var(--darkBlue);\n  border-radius: 5px;\n  padding: 0.25rem;\n}\n\n.nextButton {\n  text-align: center;\n  font-weight: 400;\n  background-color: #7c3aed;\n  font-size: 1rem;\n  color: white;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export var fromLink = `ctRMMjF0sw989JFZ0L3e`;
export var hello = `HZoeGjVuga_96UNL_J0E`;
export var input = `mPCTKYOT65ubXFkMQpHH`;
export var nextButton = `BwfFHV6_J_BWCfJXVQXN`;
export default ___CSS_LOADER_EXPORT___;
