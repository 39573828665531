// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P0NaUnjtVd3DqoIuXg6v {
  margin: 10px 0px;
  display: flex; 
  flex-direction: column;
  gap: 20px;
}

.h1z3TxsmIQQ4OPyYc6AZ {
  font-size: 1.7rem;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Form/FormPicker.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".formPicker {\n  margin: 10px 0px;\n  display: flex; \n  flex-direction: column;\n  gap: 20px;\n}\n\n.title {\n  font-size: 1.7rem;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export var formPicker = `P0NaUnjtVd3DqoIuXg6v`;
export var title = `h1z3TxsmIQQ4OPyYc6AZ`;
export default ___CSS_LOADER_EXPORT___;
