// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VPyn6ftmGpt5x_thhuFE {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px; 
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Form/FormHeader/Hashtags.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".main {\n  margin-top: 10px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px; \n}\n"],"sourceRoot":""}]);
// Exports
export var main = `VPyn6ftmGpt5x_thhuFE`;
export default ___CSS_LOADER_EXPORT___;
