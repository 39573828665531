"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.game = void 0;
const core_1 = require("@rematch/core");
const game_1 = require("newGames/types/game");
const createGameState = () => {
    return {
        id: "",
        game: "",
        name: "",
        tasks: {},
        images: {},
        currentTask: {},
        taskOrder: [],
        userAnswers: {},
        theme: "",
        step: game_1.GameSteps.game,
        fullScreen: null,
        fullScreenIcon: false,
        time: Date.now(),
        fromLink: false,
        refresh: false,
        studentName: "",
        results: { all: -1, user: -1 },
        tags: [],
        tagsId: [],
        creator: ""
    };
};
exports.game = (0, core_1.createModel)()({
    state: createGameState(),
    reducers: {
        setGame(state, ex) {
            let { tasks, taskOrder, images, tags, tags_id, creator } = ex;
            const currentTask = tasks[taskOrder[0]];
            const userAnswers = Object.assign({}, state.userAnswers);
            for (let key of taskOrder) {
                userAnswers[key] = {};
            }
            return Object.assign(Object.assign(Object.assign({}, state), ex), { userAnswers,
                currentTask,
                tags,
                creator, tagsId: tags_id, images: images || {} });
        },
        changeFullScreenIcon(state) {
            return Object.assign(Object.assign({}, state), { fullScreenIcon: !state.fullScreenIcon });
        },
        setFullScreen(state) {
            if (state.fullScreen == null) {
                return Object.assign(Object.assign({}, state), { fullScreen: true });
            }
            return Object.assign(Object.assign({}, state), { fullScreen: !state.fullScreen });
        },
        setPoints(state, payload) {
            const { userPoints, allPoints } = payload;
            return Object.assign(Object.assign({}, state), { userPoints, allPoints });
        },
        restartGame(state) {
            const userAnswers = {};
            state.taskOrder.forEach((element) => {
                userAnswers[element] = {};
            });
            return Object.assign(Object.assign({}, state), { refresh: !state.refresh, userAnswers, currentTask: state.tasks[state.taskOrder[0]], fullScreen: null, time: Date.now(), results: { all: -1, user: -1 } });
        },
        nextTask(state) {
            const { index } = state.currentTask;
            const indexNumber = state.taskOrder.indexOf(index);
            if (indexNumber + 1 == state.taskOrder.length) {
                return Object.assign(Object.assign({}, state), { currentTask: state.tasks[state.taskOrder[0]] });
            }
            return Object.assign(Object.assign({}, state), { currentTask: state.tasks[state.taskOrder[indexNumber + 1]] });
        },
        prevTask(state) {
            const { index } = state.currentTask;
            const indexNumber = state.taskOrder.indexOf(index);
            if (indexNumber == 0) {
                return Object.assign(Object.assign({}, state), { currentTask: state.tasks[state.taskOrder[state.taskOrder.length - 1]] });
            }
            return Object.assign(Object.assign({}, state), { currentTask: state.tasks[state.taskOrder[indexNumber - 1]] });
        },
        reloadGame(state) {
            return createGameState();
        },
        startExercise(state) {
            return Object.assign(Object.assign({}, state), { time: Date.now() });
        },
        setFromLink(state, payload) {
            return Object.assign(Object.assign({}, state), { studentName: payload, fromLink: true });
        },
        changeStep(state) {
            return Object.assign(Object.assign({}, state), { step: game_1.GameSteps.result });
        },
        setAnswer(state, data) {
            const { taskIndex, id, value } = data;
            const taskAnswers = Object.assign({}, state.userAnswers[taskIndex]);
            return Object.assign(Object.assign({}, state), { userAnswers: Object.assign(Object.assign({}, state.userAnswers), { [taskIndex]: Object.assign(Object.assign({}, taskAnswers), { [id]: value }) }) });
        },
        addResults(state, results) {
            return Object.assign(Object.assign({}, state), { results });
        },
        consoleState(state) {
            console.log(state);
            return Object.assign({}, state);
        },
    },
    effects: (dispatch) => ({}),
});
