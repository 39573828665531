"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.form = exports.GAME_INDEX = void 0;
const core_1 = require("@rematch/core");
const form_1 = require("newGames/types/form");
const form_2 = require("newGames/types/form");
exports.GAME_INDEX = "gameIndex";
const DEFAULT_STATE = {
    name: "",
    game: "",
    step: form_2.StepForm.task,
    taskIndex: 0,
    questionInfo: { type: form_1.FormQuestion.text, validate: [], requiredImage: false },
    theme: "",
    hashtags: new Array(4).fill(""),
    problems: { [exports.GAME_INDEX]: {} },
    images: {},
    tasks: {},
    taskOrder: [],
};
exports.form = (0, core_1.createModel)()({
    state: DEFAULT_STATE,
    reducers: {
        loadForm(state, data) {
            return Object.assign(Object.assign({}, state), data);
        },
        addTask(state, createPositions) {
            const taskIndex = `task${state.taskIndex}`;
            const positions = createPositions();
            return Object.assign(Object.assign({}, state), { tasks: Object.assign(Object.assign({}, state.tasks), { [taskIndex]: { index: taskIndex, positions, question: "" } }), taskIndex: state.taskIndex + 1, problems: Object.assign(Object.assign({}, state.problems), { [taskIndex]: {} }), images: Object.assign(Object.assign({}, state.images), { [taskIndex]: {} }), taskOrder: [...state.taskOrder, taskIndex] });
        },
        changeTheme(state, data) {
            return Object.assign(Object.assign({}, state), { theme: data });
        },
        changeHashtags(state, { index, value }) {
            return Object.assign(Object.assign({}, state), { hashtags: state.hashtags.map((e, i) => {
                    if (i == index) {
                        return value;
                    }
                    return e;
                }) });
        },
        changeName(state, value) {
            return Object.assign(Object.assign({}, state), { name: value });
        },
        removeTask(state, index) {
            const tasks = Object.assign({}, state.tasks);
            const problems = Object.assign({}, state.problems);
            const images = Object.assign({}, state.images);
            delete tasks[index];
            delete problems[index];
            delete images[index];
            return Object.assign(Object.assign({}, state), { tasks,
                problems,
                images, taskOrder: state.taskOrder.filter((element) => element !== index) });
        },
        addImage(state, data) {
            const { index, image, id } = data;
            const taskImages = state.images[index];
            return Object.assign(Object.assign({}, state), { images: Object.assign(Object.assign({}, state.images), { [index]: Object.assign(Object.assign({}, taskImages), { [id]: image }) }) });
        },
        removeImage(state, data) {
            const { index, id } = data;
            const taskImages = state.images[index];
            if (!taskImages) {
                return Object.assign({}, state);
            }
            delete taskImages[id];
            return Object.assign(Object.assign({}, state), { images: Object.assign(Object.assign({}, state.images), { [index]: Object.assign({}, taskImages) }) });
        },
        setPositions(state, data) {
            const { index, positions, remove } = data;
            const taskProblems = Object.assign({}, state.problems[index]);
            const taskImages = Object.assign({}, state.images[index]);
            const task = state.tasks[index];
            if (remove && remove.length) {
                for (let key of remove) {
                    delete taskProblems[key];
                    delete taskImages[key];
                }
            }
            return Object.assign(Object.assign({}, state), { tasks: Object.assign(Object.assign({}, state.tasks), { [index]: Object.assign(Object.assign({}, task), { positions }) }), problems: Object.assign(Object.assign({}, state.problems), { [index]: taskProblems }), images: Object.assign(Object.assign({}, state.images), { [index]: taskImages }) });
        },
        changeTaskQuestion(state, data) {
            const { index, value } = data;
            const task = state.tasks[index];
            return Object.assign(Object.assign({}, state), { tasks: Object.assign(Object.assign({}, state.tasks), { [index]: Object.assign(Object.assign({}, task), { question: value }) }) });
        },
        addProblem(state, data) {
            const { index, id, text } = data;
            const taskProblems = state.problems[index] || {};
            const problem = taskProblems[id] || { show: false };
            return Object.assign(Object.assign({}, state), { problems: Object.assign(Object.assign({}, state.problems), { [index]: Object.assign(Object.assign({}, taskProblems), { [id]: Object.assign(Object.assign({}, problem), { text }) }) }) });
        },
        removeProblem(state, data) {
            const { index, id } = data;
            const taskProblems = state.problems[index]
                ? Object.assign({}, state.problems[index]) : false;
            if (!taskProblems) {
                return Object.assign({}, state);
            }
            delete taskProblems[id];
            return Object.assign(Object.assign({}, state), { problems: Object.assign(Object.assign({}, state.problems), { [index]: taskProblems }) });
        },
        changeStep(state, value) {
            return Object.assign(Object.assign({}, state), { step: value });
        },
        copyTask(state, index) {
            const newIndex = `task${state.taskIndex}`;
            const task = state.tasks[index];
            const taskImages = state.images[index] || {};
            const newTask = JSON.parse(JSON.stringify(task));
            return Object.assign(Object.assign({}, state), { tasks: Object.assign(Object.assign({}, state.tasks), { [newIndex]: Object.assign(Object.assign({}, newTask), { index: newIndex }) }), taskIndex: state.taskIndex + 1, problems: Object.assign(Object.assign({}, state.problems), { [newIndex]: {} }), images: Object.assign(Object.assign({}, state.images), { [newIndex]: Object.assign({}, taskImages) }), taskOrder: [...state.taskOrder, newIndex] });
        },
        finish(state) {
            let checkGood = true;
            const problems = Object.assign({}, state.problems);
            for (let key in problems) {
                const taskProblems = problems[key];
                if (Object.keys(taskProblems).length) {
                    if (checkGood) {
                        checkGood = false;
                    }
                    for (let key in taskProblems) {
                        taskProblems[key] = Object.assign(Object.assign({}, taskProblems[key]), { show: true });
                    }
                }
            }
            if (!checkGood) {
                return Object.assign(Object.assign({}, state), { problems });
            }
            return Object.assign(Object.assign({}, state), { step: form_2.StepForm.theme });
        },
        resetForm(state) {
            return Object.assign({}, DEFAULT_STATE);
        },
        print(state) {
            console.log(state);
            return Object.assign({}, state);
        },
    },
    effects: (dispatch) => ({}),
});
