// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xxRJzqPZ_GCN64bZdZYK {
  font-family: "Rubik";
}


.F7z2IaDgDN7sHsn_2kj6 { 
  font-size: 1.2rem;
}


@media (max-width >= 700) { 
  .F7z2IaDgDN7sHsn_2kj6 { 
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Game/GameResults.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;;AAGA;EACE,iBAAiB;AACnB;;;AAGA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".results {\n  font-family: \"Rubik\";\n}\n\n\n.title { \n  font-size: 1.2rem;\n}\n\n\n@media (max-width >= 700) { \n  .title { \n    font-size: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
export var results = `xxRJzqPZ_GCN64bZdZYK`;
export var title = `F7z2IaDgDN7sHsn_2kj6`;
export default ___CSS_LOADER_EXPORT___;
