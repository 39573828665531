// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b1O6kJqgBl8w8KskNH2A {
  height: 700px;
  max-width: 1300px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.p6WOUHcu1r_H2oEbDd9e {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-color: #5dc5ff;
}

.VbojyM0qS9czHSYiSJV0 {
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
}

.phBFPUKhyYNeqwHzwCYa {
  position: absolute;
  border-radius: 10px;
}

.lPOuOJM8XJODXcPgNGtM {
  width: 100%;
  height: 100%;
}

.HtGA97_08DSXRyBaUhkr {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #7c3aed;
  padding: 0.27rem 0.32rem;
  border-radius: 5px;
  gap: 8px;
  display: flex;
  align-items: center;
  background-color: #ede9fe;
}

.ZithMTudHMSx5KNoTdPk {
  justify-content: space-between;
}

.f8Ub4oj9o8KuKK3sgAkd {
  min-height: 200px;
}

@media (min-height: 1300px) {
  .V5deBjoSIRcAfh6EJDTb {
    height: 900px;
  }
}

@media (min-height: 640px) {
  .V5deBjoSIRcAfh6EJDTb {
    height: 900px;
  }
}

@media (max-width: 640px) {
  .ZithMTudHMSx5KNoTdPk {
    justify-content: center;
  }

  .HtGA97_08DSXRyBaUhkr {
    font-size: 14px;
  }
}

@media (max-width: 440px) {

  .HtGA97_08DSXRyBaUhkr {
    font-size: 12px;
  }
}

`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Game/GameContainer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,wBAAwB;EACxB,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;;EAEE;IACE,eAAe;EACjB;AACF","sourcesContent":[".gameContainer {\n  height: 700px;\n  max-width: 1300px;\n  overflow: hidden;\n  position: relative;\n  border-radius: 10px;\n}\n\n.game {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  background-size: cover;\n  background-position: center;\n  background-color: #5dc5ff;\n}\n\n.gameName {\n  font-size: 1.3rem;\n  margin-bottom: 0.4rem;\n}\n\n.themeAnimation {\n  position: absolute;\n  border-radius: 10px;\n}\n\n.svgThemeAnimation {\n  width: 100%;\n  height: 100%;\n}\n\n.footerButton {\n  cursor: pointer;\n  font-size: 1rem;\n  font-weight: 500;\n  color: #7c3aed;\n  padding: 0.27rem 0.32rem;\n  border-radius: 5px;\n  gap: 8px;\n  display: flex;\n  align-items: center;\n  background-color: #ede9fe;\n}\n\n.footer {\n  justify-content: space-between;\n}\n\n.tableResult {\n  min-height: 200px;\n}\n\n@media (min-height: 1300px) {\n  .exercise {\n    height: 900px;\n  }\n}\n\n@media (min-height: 640px) {\n  .exercise {\n    height: 900px;\n  }\n}\n\n@media (max-width: 640px) {\n  .footer {\n    justify-content: center;\n  }\n\n  .footerButton {\n    font-size: 14px;\n  }\n}\n\n@media (max-width: 440px) {\n\n  .footerButton {\n    font-size: 12px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export var gameContainer = `b1O6kJqgBl8w8KskNH2A`;
export var game = `p6WOUHcu1r_H2oEbDd9e`;
export var gameName = `VbojyM0qS9czHSYiSJV0`;
export var themeAnimation = `phBFPUKhyYNeqwHzwCYa`;
export var svgThemeAnimation = `lPOuOJM8XJODXcPgNGtM`;
export var footerButton = `HtGA97_08DSXRyBaUhkr`;
export var footer = `ZithMTudHMSx5KNoTdPk`;
export var tableResult = `f8Ub4oj9o8KuKK3sgAkd`;
export var exercise = `V5deBjoSIRcAfh6EJDTb`;
export default ___CSS_LOADER_EXPORT___;
