import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import purpuleCheck from "assets/purpuleCheck.svg";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SAVE_ACTIVE_HASHTAGS_MY_TASKS_SUCCESS,
  SEARCH_EXERCISES,
} from "store/actionTypes";
import { fetchExercises } from "store/actions/exercises";
import { fetchFolders } from "store/actions/folders";
import { fetchUserMeExercisesCount } from "store/actions/userMe";
import Folder from "components/components/folder/Folder";
import TaskCard from "components/components/taskCard/TaskCard";
import learning from "assets/learning.png";
import api from "newGames/axios";
import { useState } from "react";
import { fetchSearchHashtags } from "store/actions/hashtags";
import "./style.css";
import { NewGamesTaskCard } from "newGames/components/Panels/NewGamesTaskCard";
import { Loading } from "newGames/components/Loading";
import axios from "axios";

function MyTasks(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const temp = useRef();
  const [searchType, setSearchType] = useState("tasks");
  const [newTasks, setNewTasks] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [newFetchReady, setNewFetchReady] = useState(false);
  const [showFolders, setShowFolders] = React.useState(false);

  const startFunction = () => {
    props.fetchFolders();
    props.fetchExercises("search_name", props.search);
    props.fetchUserMeExercisesCount();
  };

  temp.current = startFunction;

  useEffect(() => {
    temp.current();
  }, []);

  useEffect(() => {
    const getNewData = async () => {
      try {
        const response = await api.get("/games/mygame");
        console.log(response.data);
        setNewTasks(response.data);
      } catch (problem) {
        console.error(problem);
      } finally {
        if (!newFetchReady) {
          setNewFetchReady(true);
        }
      }
    };
    getNewData();
  }, [refresh]);

  useEffect(() => {
    if (searchType === "tasks" || !props.search) {
      props.fetchExercises("search_name", props.search);
    }
    if (props.search) {
      props.fetchSearchHashtags("search_name", props.search);
    }
  }, [props.search, searchType]);

  useEffect(() => {
    if (props.search && searchType === "hashtags") {
      props.fetchExercises(
        "hashtag_ids",
        props.active_hashtags.map((el) => el.id)
      );
    }
  }, [props.active_hashtags, searchType, props.search]);

  const onClickHashtag = (item) => {
    if (!props.active_hashtags.find((el) => el.id === item.id)) {
      props.SAVE_ACTIVE_HASHTAGS([...props.active_hashtags, item]);
    } else {
      props.SAVE_ACTIVE_HASHTAGS(
        props.active_hashtags.filter((el) => el.id !== item.id)
      );
    }
  };

  const filterNewTasks = (element) => {
    let checkHashtags = false;
    if (props.active_hashtags.length > 0) {
      for (let key of props.active_hashtags) {
        if (element.tags_id.includes(key?.id)) {
          checkHashtags = true;
          break;
        }
      }
    }

    return element.name.includes(props.search) || checkHashtags;
  };

  const renderEmptyTasks = () => {
    if (!newFetchReady) {
      return <Loading problem={false} color={"var(--fiol)"} />;
    }

    return (
      <div className="noTasks">
        <img src={learning} alt="learning" />
        <p>{t(`myTask.noTasks`)}</p>
        <button onClick={() => navigate(`../create_task/`)}>
          {t(`myTask.create_task`)}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="myTask">
        <div className="folders">
          {showFolders ? (
            <>
              <Folder
                key={"hideFolder"}
                type={"showFolders"}
                item={{ text: "Скрыть папки" }}
                onClick={() => setShowFolders(false)}
              />
              <Folder
                key={"addFolder"}
                type="create"
                onClick={props.setVisible}
              />
              {props.folders.map((item, key) => {
                return (
                  <Folder
                    key={key}
                    item={item}
                    onClick={() => navigate(`../folder/${item.id}`)}
                  />
                );
              })}
            </>
          ) : (
            <Folder
              key={"showFolder"}
              type={"showFolders"}
              item={{ text: "Все папки" }}
              onClick={() => setShowFolders(true)}
            />
          )}
        </div>
        <div className="allTasks_header">
          <p>{t(`myTask.all_tasks`)}</p>
          <div style={{ position: "relative" }}>
            <Input
              value={props.search}
              className="header_actionBlock__input"
              placeholder={t(`header.search_placeholder`)}
              prefix={<SearchOutlined />}
              style={{ width: 220 }}
              onChange={(e) => props.SEARCH_EXERCISES(e.target.value)}
            />
            {props.search && (
              <div className="myTasks_searchBlock">
                <div
                  className={`myTasks_searchBlock_item ${
                    searchType === "tasks" && "active"
                  }`}
                  onClick={() => setSearchType("tasks")}
                >
                  <p>{t(`header.search.tasks`)}</p>
                  <img src={purpuleCheck} alt="purpuleCheck" />
                </div>
                <div
                  className={`myTasks_searchBlock_item ${
                    searchType === "hashtags" && "active"
                  }`}
                  onClick={() => setSearchType("hashtags")}
                >
                  <p>{t(`header.search.hashtags`)}</p>
                  <img src={purpuleCheck} alt="purpuleCheck" />
                </div>
              </div>
            )}
          </div>
        </div>
        {searchType === "hashtags" && props.search && (
          <div className="hashtags_list">
            {props.active_hashtags.map((el) => {
              if (!props.search_hashtags.find((item) => item.id === el.id)) {
                return (
                  <div
                    key={`search_hashtags_${el.id}`}
                    className={`search_list__hashtagsItem search_list__hashtagsItem__active`}
                    onClick={() => onClickHashtag(el)}
                  >
                    #{el.name}
                  </div>
                );
              }
            })}
            {props.search_hashtags.map((item) => (
              <div
                key={`search_hashtags_${item.id}`}
                className={`search_list__hashtagsItem  ${
                  props.active_hashtags.find((el) => el.id === item.id)
                    ? "search_list__hashtagsItem__active"
                    : ""
                }`}
                onClick={() => onClickHashtag(item)}
              >
                #{item.name}
              </div>
            ))}
          </div>
        )}
        {props.exercises.length === 0 && newTasks.length == 0 ? (
          renderEmptyTasks()
        ) : (
          <>
            <div
              className="patternsBlock_list myTasks_list"
              style={{ fontFamily: "Rubik" }}
            >
              {newTasks.filter(filterNewTasks).map((element) => {
                return (
                  <div className="d-flex justify-content-center">
                    <NewGamesTaskCard
                      key={element.id}
                      id={element.id}
                      type={element.game}
                      isOpen={element.isOpen}
                      task={element.first_task}
                      images={element.first_task_images}
                      name={element.name}
                      taskTheme={element.theme}
                      folders={props.folders}
                      setRefresh={setRefresh}
                    />
                  </div>
                );
              })}
              {newFetchReady &&
                props.exercises.map((item) => (
                  <div key={item.id} className="myTasks_list__item">
                    <TaskCard
                      item={item}
                      fetch={props.fetchFolders}
                      onClick={() => navigate(`../task/${item.id}/watch`)}
                      actionButton="Результаты"
                      folders={props.folders}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    folders: state.folders.folders,
    exercises: state.exercises.exercises,
    active_hashtags: state.exercises.active_hashtags,
    search: state.exercises.search,
    search_hashtags: state.search_hashtags.hashtags,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: (text) => dispatch({ type: "setVisible", payload: text }),
    SEARCH_EXERCISES: (text) =>
      dispatch({ type: SEARCH_EXERCISES, payload: text }),
    fetchFolders: () => dispatch(fetchFolders()),
    fetchExercises: (type, text) => dispatch(fetchExercises(type, text)),
    fetchSearchHashtags: (type, text) =>
      dispatch(fetchSearchHashtags(type, text)),
    fetchUserMeExercisesCount: () => dispatch(fetchUserMeExercisesCount()),
    SAVE_ACTIVE_HASHTAGS: (hashtags) =>
      dispatch({
        type: SAVE_ACTIVE_HASHTAGS_MY_TASKS_SUCCESS,
        hashtags,
      }),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(MyTasks);
