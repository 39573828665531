import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { api_exercises } from "API/exercises";
import TaskCard from "./taskCard/TaskCard";
import { NewGamesTaskCard } from "newGames/components/Panels/NewGamesTaskCard";
import { getQueryStringFromObject } from "functions/filter";
import { BASE_URL } from "newGames/constants";
import { Loading } from "newGames/components/Loading";
import api from "newGames/axios";

export function HashtagsSearch() {
  const { hashtag, hashtagName } = useParams();
  const [status, setStatus] = React.useState("loading");
  const [oldGames, setOldGames] = React.useState([]);
  const [newGames, setNewGames] = React.useState([]);

  const navigate = useNavigate();

  const fetchGames = async () => {
    try {
      let obj = {};
      obj["hashtag_ids"] = [hashtag];
      const params = getQueryStringFromObject(JSON.stringify(obj));
      const oldGames = await api_exercises.SearchExercises(params);
      const newGames = await api.get(`${BASE_URL}/games/hashtag/${hashtag}`);
      setNewGames(newGames.data);
      setOldGames(oldGames.data);
      setStatus("ready");
    } catch (error) {
      setStatus("error");
    }
  };

  React.useEffect(() => {
    fetchGames();
  }, []);

  if (status == "error") {
    return <div>Возникла ошибка</div>;
  }

  if (status == "loading") {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <Loading problem={false} color={"var(--fiol)"} />
      </div>
    );
  }

  return (
    <div>
      <div
        className="d-flex justify-content-center mb-3"
        style={{
          textAlign: "center",
          fontFamily: "Rubik",
          fontSize: "1.8rem",
          fontWeight: "bold",
        }}
      >
        {`Поиск по хештегу #${hashtagName}`}
      </div>
      <div className="d-flex justify-content-center align-items-center flex-wrap g-m mb-2">
        {oldGames.map((item) => {
          return (
            <TaskCard
              item={item}
              onClick={() => navigate(`/task/${item.id}/watch`)}
              actionButton="Сохранить к себе"
            />
          );
        })}
        {newGames.map((element) => {
          const firstTask = Object.keys(element.tasks)[0];
          if (!firstTask) {
            return;
          }
          const task = element?.tasks?.[firstTask];

          if (!task) {
            return;
          }
          const images = element?.images?.[firstTask] || {};
          return (
            <NewGamesTaskCard
              id={element.id}
              type={element.game}
              isOpen={element.isOpen}
              task={task}
              images={images}
              name={element.name}
              taskTheme={element.theme}
              setRefresh={() => true}
              noMenu={true}
              actionClick={() => navigate("/myTask")}
              actionButton="none"
            />
          );
        })}
        {!newGames.length && !oldGames.length && "Ничего не найдено"}
      </div>
    </div>
  );
}
