"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapDispatchImageInput = exports.mapDispatchProblemInput = exports.validateImage = exports.validateText = void 0;
const validates_1 = require("newGames/components/Form/Inputs/validates");
const validateText = (value, validates) => {
    for (let key of validates) {
        let func;
        if (typeof key == "function") {
            func = key;
        }
        else {
            func = validates_1.validators[key];
        }
        if (!func) {
            continue;
        }
        const result = func(value);
        if (result) {
            return result;
        }
    }
    return "";
};
exports.validateText = validateText;
const validateImage = (value, requiredImage) => {
    if (!value && requiredImage) {
        return "Изображение обязательно";
    }
    return "";
};
exports.validateImage = validateImage;
const mapDispatchProblemInput = (dispatch, ownProps) => ({
    removeProblem: () => dispatch.form.removeProblem({
        id: ownProps.id,
        index: ownProps.taskIndex,
    }),
    addProblem: (text) => dispatch.form.addProblem({
        text,
        id: ownProps.id,
        index: ownProps.taskIndex,
    }),
});
exports.mapDispatchProblemInput = mapDispatchProblemInput;
const mapDispatchImageInput = (dispatch, ownProps) => ({
    addImage: (image) => dispatch.form.addImage({
        id: ownProps.id,
        image,
        index: ownProps.taskIndex,
    }),
    removeImage: () => dispatch.form.removeImage({
        id: ownProps.id,
        index: ownProps.taskIndex,
    }),
});
exports.mapDispatchImageInput = mapDispatchImageInput;
