// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zq2YH4HUz0Kc1YoL3cAy {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.8rem;
  justify-content: center;
}

.hQFjz_cvG7KnaDBzxWKj {
  flex-basis: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 7px;
  min-height: 70px;
}

.f1s0wv1ofOR_lkLW0OLP {
  font-size: 1.2rem;
  border-radius: 6px;
  width: 100%;
  min-height: 40px;
  text-align: center;
  font-size: 1.8rem;
  color: black;
}

.f1s0wv1ofOR_lkLW0OLP::placeholder {
  color: #b1b1b1;
}

@media (max-width: 788px) {
  .Zq2YH4HUz0Kc1YoL3cAy {
    font-size: 1.3rem;
  }
}

@media (max-width: 600px) {
  .hQFjz_cvG7KnaDBzxWKj {
    flex-basis: 90%;
  }
}

@media (max-width: 440px) {
  .f1s0wv1ofOR_lkLW0OLP {
    font-size: 1.3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/games/ExtendedAnswer/ExtendedAnswer.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,4BAA4B;EAC5B,2BAA2B;EAC3B,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".question {\n  margin-bottom: 1rem;\n  font-weight: bold;\n  font-size: 1.8rem;\n  justify-content: center;\n}\n\n.image {\n  flex-basis: 50%;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  border-radius: 7px;\n  min-height: 70px;\n}\n\n.inputAnswer {\n  font-size: 1.2rem;\n  border-radius: 6px;\n  width: 100%;\n  min-height: 40px;\n  text-align: center;\n  font-size: 1.8rem;\n  color: black;\n}\n\n.inputAnswer::placeholder {\n  color: #b1b1b1;\n}\n\n@media (max-width: 788px) {\n  .question {\n    font-size: 1.3rem;\n  }\n}\n\n@media (max-width: 600px) {\n  .image {\n    flex-basis: 90%;\n  }\n}\n\n@media (max-width: 440px) {\n  .inputAnswer {\n    font-size: 1.3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var question = `Zq2YH4HUz0Kc1YoL3cAy`;
export var image = `hQFjz_cvG7KnaDBzxWKj`;
export var inputAnswer = `f1s0wv1ofOR_lkLW0OLP`;
export default ___CSS_LOADER_EXPORT___;
