"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormStep = exports.StepForm = exports.FormQuestion = void 0;
var FormQuestion;
(function (FormQuestion) {
    FormQuestion["image"] = "image";
    FormQuestion["text"] = "text";
    FormQuestion["all"] = "all";
    FormQuestion["none"] = "none";
})(FormQuestion || (exports.FormQuestion = FormQuestion = {}));
var StepForm;
(function (StepForm) {
    StepForm["task"] = "task";
    StepForm["theme"] = "theme";
})(StepForm || (exports.StepForm = StepForm = {}));
var FormStep;
(function (FormStep) {
    FormStep["TASK"] = "task";
    FormStep["THEME"] = "theme";
})(FormStep || (exports.FormStep = FormStep = {}));
