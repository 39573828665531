// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpXdQuFElNIHIfkPjI5M {
  text-align: center;
  color: var(--darkBlue);
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
}

.Su10ja72lpAe5rc1L0T0 {
  table-layout: fixed;
}

.Su10ja72lpAe5rc1L0T0 th td { 
  word-break: break-all;
}

.Di_MN1PnO8n4RIR4TdR1 { 
  
}

@media (max-width: 600px) {
  .Su10ja72lpAe5rc1L0T0 {
    font-size: 0.6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Game/TableResults.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;AAEA;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".title {\n  text-align: center;\n  color: var(--darkBlue);\n  font-size: 1.1rem;\n  font-weight: 600;\n  margin-bottom: 0.7rem;\n}\n\n.table {\n  table-layout: fixed;\n}\n\n.table th td { \n  word-break: break-all;\n}\n\n.moreInformation { \n  \n}\n\n@media (max-width: 600px) {\n  .table {\n    font-size: 0.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var title = `OpXdQuFElNIHIfkPjI5M`;
export var table = `Su10ja72lpAe5rc1L0T0`;
export var moreInformation = `Di_MN1PnO8n4RIR4TdR1`;
export default ___CSS_LOADER_EXPORT___;
