// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y5oloSmsUPJtTMhKFPKl {
  font-family: "Rubik";
  font-size: 1rem;
  margin: 0 auto;
  padding: 0 100px;
}

@media (max-width: 680px) {
  .y5oloSmsUPJtTMhKFPKl { 
    padding: 0 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/games/form.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".form {\n  font-family: \"Rubik\";\n  font-size: 1rem;\n  margin: 0 auto;\n  padding: 0 100px;\n}\n\n@media (max-width: 680px) {\n  .form { \n    padding: 0 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var form = `y5oloSmsUPJtTMhKFPKl`;
export default ___CSS_LOADER_EXPORT___;
