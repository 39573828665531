// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxnlSfTmHcSdXnr7LLl {
}

.w25lhD7toWiR11eEn3Gf {
  width: 24px;
  height: 24px;
}

@media (max-width: 680px) {
  .boxnlSfTmHcSdXnr7LLl {
    width: 15px;
    height: 15px;
  }
  .w25lhD7toWiR11eEn3Gf {
    width: 15px;
    height: 15px;
  }
}

@media (max-width: 680px) {
  .boxnlSfTmHcSdXnr7LLl {
    width: 15px;
    height: 15px;
  }
  .w25lhD7toWiR11eEn3Gf {
    width: 15px;
    height: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/forms/Table/Table.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".removeColImage {\n}\n\n.emptyIcon {\n  width: 24px;\n  height: 24px;\n}\n\n@media (max-width: 680px) {\n  .removeColImage {\n    width: 15px;\n    height: 15px;\n  }\n  .emptyIcon {\n    width: 15px;\n    height: 15px;\n  }\n}\n\n@media (max-width: 680px) {\n  .removeColImage {\n    width: 15px;\n    height: 15px;\n  }\n  .emptyIcon {\n    width: 15px;\n    height: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var removeColImage = `boxnlSfTmHcSdXnr7LLl`;
export var emptyIcon = `w25lhD7toWiR11eEn3Gf`;
export default ___CSS_LOADER_EXPORT___;
