import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOneFolder } from "store/actions/oneFolder";

import FolderComponent from "components/components/folder/Folder";
import TaskCard from "components/components/taskCard/TaskCard";
import api from "newGames/axios";
import del from "assets/delete.png";
import edit from "assets/edit.svg";
import learning from "assets/learning.png";
import leftGreyArrow from "assets/leftGreyArrow.svg";
import { NewGamesTaskCard } from "newGames/components/Panels/NewGamesTaskCard";

import "./style.css";

function Folder({ one_folder, setVisible, fetchOneFolder }) {
  const { id } = useParams();
  const temp = useRef();
  const { t } = useTranslation();
  const [refresh, setRefresh] = React.useState(false);
  const [newGames, setNewGames] = React.useState([]);
  const [newFetch, setNewFetch] = React.useState(false);
  const navigate = useNavigate();

  const startFunction = () => {
    fetchOneFolder(id);
  };

  const getNewGames = async () => {
    try {
      setNewFetch(false);
      await fetchOneFolder(id);
      const newGames = await api.get(`/games/folder/${id}`);
      setNewGames(newGames.data);
    } catch {
    } finally {
      setNewFetch(true);
    }
  };

  temp.current = startFunction;

  useEffect(() => {
    temp.current();
  }, [id]);

  useEffect(() => {
    setNewFetch(false);
    getNewGames();
  }, [id, refresh]);

  return (
    <>
      {one_folder !== null && (
        <div className="myTask">
          <div
            className="allTasks_header folderPage_headerBlock"
            style={{ marginTop: 0 }}
          >
            <div className="folderPage_header">
              <img
                src={leftGreyArrow}
                alt="leftGreyArrow"
                onClick={() => navigate(-1)}
              />
              <p>{one_folder.name}</p>
              <div>
                <img
                  src={edit}
                  alt="edit"
                  onClick={() => setVisible("renameFolder")}
                />
                <img
                  src={del}
                  alt="del"
                  className="deleteIcon"
                  onClick={() => setVisible("deleteFolder")}
                />
              </div>
            </div>
            <Input
              className="header_actionBlock__input"
              placeholder={t(`header.search_placeholder`)}
              prefix={<SearchOutlined />}
              style={{ width: 220 }}
            />
          </div>
          <div className="folders" style={{ marginTop: 42 }}>
            {one_folder.children.map((item, key) => {
              return (
                <FolderComponent
                  key={key}
                  item={item}
                  onClick={() => navigate(`../folder/${item.id}`)}
                />
              );
            })}
            <FolderComponent
              key={"addFolder"}
              type="create_subfolder"
              onClick={setVisible}
            />
          </div>
          {one_folder.exercises.length === 0 && newGames.length == 0 ? (
            <div className="noTasks">
              <img src={learning} alt="learning" />
              <p>Нет заданий, добавьте задание</p>
              <button onClick={() => navigate(`../myTask`)}>
                Добавить задание
              </button>
            </div>
          ) : (
            <div
              className="patternsBlock_list folderTasks_list"
              style={{ marginTop: 0 }}
            >
              {newFetch &&
                newGames.map((element) => {
                  const firstTask = Object.keys(element.tasks)[0];
                  if (!firstTask) {
                    return;
                  }
                  const task = element?.tasks?.[firstTask];

                  if (!task) {
                    return;
                  }
                  const images = element?.images?.[firstTask] || {};
                  return (
                    <div className="d-flex justify-content-center">
                      <NewGamesTaskCard
                        key={element.id}
                        id={element.id}
                        type={element.game}
                        isOpen={element.isOpen}
                        task={task}
                        images={images}
                        name={element.name}
                        taskTheme={element.theme}
                        folders={one_folder.children}
                        setRefresh={setRefresh}
                        folder={id}
                        noLoader={true}
                      />
                    </div>
                  );
                })}
              {newFetch &&
                one_folder.exercises.map((item, key) => {
                  return (
                    <div key={key} className="folderTasks_list__item">
                      <TaskCard
                        item={item}
                        fetch={() => fetchOneFolder(id)}
                        onClick={() => navigate(`../task/${item.id}/watch`)}
                        actionButton="Результаты"
                        folders={one_folder.children}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    one_folder: state.one_folder.one_folder,
  };
}

function mapDispatvhToProps(dispatch) {
  return {
    setVisible: (text, data) =>
      dispatch({ type: "setVisible", payload: text, data }),
    fetchOneFolder: (id) => dispatch(fetchOneFolder(id)),
  };
}

export default connect(mapStateToProps, mapDispatvhToProps)(Folder);
