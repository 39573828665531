// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R4hdlCTyo_3IW_jsZDTg {
  width: 120px;
  padding: 9px 14px;
  font-size: 1.1rem;
  color: white;
  line-height: 110%;
  background-color: #002876;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Game/GameHeader.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".headerButton {\n  width: 120px;\n  padding: 9px 14px;\n  font-size: 1.1rem;\n  color: white;\n  line-height: 110%;\n  background-color: #002876;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export var headerButton = `R4hdlCTyo_3IW_jsZDTg`;
export default ___CSS_LOADER_EXPORT___;
