// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QILHuO41U_CkjKZb6lJS {
  color: var(--fiol);
  font-size: 0.9rem;
  font-weight: 600;
}

.SNqULr7locMA2PsPlZmf {
  min-height: 60px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/forms/Quiz/Quiz.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".addText {\n  color: var(--fiol);\n  font-size: 0.9rem;\n  font-weight: 600;\n}\n\n.question {\n  min-height: 60px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
export var addText = `QILHuO41U_CkjKZb6lJS`;
export var question = `SNqULr7locMA2PsPlZmf`;
export default ___CSS_LOADER_EXPORT___;
