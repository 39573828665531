// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xQlN6NBbXCcYI_HzLNWP {
}

.BejxBKTOxuBER_pEw61_ {
}

@media (max-width: 700px) {
  .xQlN6NBbXCcYI_HzLNWP {
    width: 20px;
    height: 20px;
  }

  .BejxBKTOxuBER_pEw61_ {
    font-size: 0.8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./../newGames/src/components/Form/AddButton.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;AACA;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".plus {\n}\n\n.text {\n}\n\n@media (max-width: 700px) {\n  .plus {\n    width: 20px;\n    height: 20px;\n  }\n\n  .text {\n    font-size: 0.8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var plus = `xQlN6NBbXCcYI_HzLNWP`;
export var text = `BejxBKTOxuBER_pEw61_`;
export default ___CSS_LOADER_EXPORT___;
